var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "recodeSelect" },
    [
      _c(
        "a-select",
        {
          staticStyle: { width: "100%" },
          on: { change: _vm.change },
          model: {
            value: _vm.val,
            callback: function ($$v) {
              _vm.val = $$v
            },
            expression: "val",
          },
        },
        _vm._l(_vm.options, function (item, index1) {
          return _c(
            "a-select-option",
            { key: "recodeSelectItem" + index1, attrs: { value: item.value } },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }